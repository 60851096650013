import Home from './components/home/Home'
import LegalMentions from './components/home/LegalMentions'
import About from './components/home/About'
import Login from './components/Auth/Login'
import Signup from './components/Auth/Signup'
import ResetPass from './components/Auth/ResetPass'
import ConfirmReset from './components/Auth/ConfirmReset'
import FourOfour from './components/UI/FourOfour'

import Dashboard from './components/dashboard/Dashboard'
import ConsultationDetail from './components/dashboard/ConsultationDetail'
import ConsultationForm from './components/dashboard/ConsultationForm'
import Doctors from './components/doctors/Doctors'

export default [
    {
        path: '/', 
        component: Home, 
        name: 'home'
    },
    {
        path: '/connexion', 
        component: Login, 
        name: 'login',
        beforeEnter: (to, from, next) => {
            if(localStorage.isLogged === "true"){
                next({
                    name: 'dashboard'
                });
            }else{
                next();
            }
        }
    },
    {
        path: '/inscription', 
        component: Signup, 
        name: 'signup',
        beforeEnter: (to, from, next) => {
            if(localStorage.isLogged === "true"){
                next({
                    name: 'dashboard'
                });
            }else{
                next();
            }
        }
    },
    {
        path: '/mentions-legales', 
        component: LegalMentions, 
        name: 'legal',
    },
    {
        path: '/a-propos', 
        component: About, 
        name: 'about',
    },
    {
        path: '/reset-mdp', 
        component: ResetPass, 
        name: 'reset-pass',
    },
    {
        path: '/confirm-reset/:resetPassToken',
        component: ConfirmReset, 
        name: 'confirm-reset',
        props: true,
    },
    {
        path: '/espace-perso', 
        component: Dashboard, 
        name: 'dashboard',
        beforeEnter: (to, from, next) => {
            if(localStorage.isLogged === "false"){
                next({
                    name: 'login'
                });
            }else{
                next();
            }
        }
    },
    {
        path: '/nouvelle-consultation/', 
        component: ConsultationForm, 
        name: 'consultationForm',
        beforeEnter: (to, from, next) => {
            if(localStorage.isLogged === "false"){
                next({
                    name: 'login'
                });
            }else{
                next();
            }
        }
    },
    {
        path: '/details/:consultationId(\\d+)',
        component: ConsultationDetail, 
        name: 'consultationDetail',
        props: true,
        beforeEnter: (to, from, next) => {
            if(localStorage.isLogged === "false"){
                next({
                    name: 'login'
                });
            }else{
                next();
            }
        }
    },
    {
        path: '/docteurs', 
        component: Doctors, 
        name: 'doctors',
        beforeEnter: (to, from, next) => {
            if(localStorage.isLogged === "false"){
                next({
                    name: 'login'
                });
            }else{
                next();
            }
        }
    },
    {
        path: '*', 
        component: FourOfour,
        name: '404'
    },


]