<template>
  <v-container fill-height id="legals">
    <v-layout align-center justify-center>
      <v-flex xs-12 sm8 md8>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card class="elevation-12 main__card mx-auto">
            <v-toolbar dark color="blue">
              <v-toolbar-title>Mentions légales</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <h1>
                Les mentions légales suivantes régissent l&#39;accès et
                l&#39;utilisation de la web-application mobile dénommée «NOM»
                (ci-après &quot;la Web-application&quot;).
              </h1>
              <div>
                <p>
                  La Web-application est un outil de recueil de données et ne
                  contient pas d&#39;éléments guidant la prise en charge qui
                  relève de la responsabilité du professionnel de santé. Le
                  recueil des données proposé par cette web-application ne
                  prétend pas à l&#39;exhaustivité et ne saurait, en aucun cas,
                  être opposable à l&#39;exercice médical ou aux décisions qui
                  en relèvent.
                </p>
                <p>La Web-application ne contient aucune publicité.</p>
                <p>
                  La Web-application est un outil de recueil de données et ne
                  contient pas d&#39;éléments guidant la prise en charge qui
                  relève de la responsabilité du professionnel de santé. Le
                  recueil des données proposé par cette web-application ne
                  prétend pas à l&#39;exhaustivité et ne saurait, en aucun cas,
                  être opposable à l&#39;exercice médical ou aux décisions qui
                  en relèvent.
                </p>
                <p>La Web-application ne contient aucune publicité.</p>
              </div>

              <h2>Conditions d&#39;utilisation</h2>

              <h3>Territorialité</h3>

              <p>
                La Web-application est à l&#39;usage des personnes résidant
                en France.
              </p>

              <h3>Identification</h3>
              <p>
                La Web-application est éditée par l’Association ANDAR,
                Service Administratif - Le Scribe, Bâtiment A - 160 avenue de
                Fès - 34080 MONTPELLIER.
              </p>
              <p>Réalisation : KALEVAPURO</p>
              <p>Objet de la Web-application</p>
              <p>
                La Web-application est une web-application visant à améliorer
                la prise en charge de la maladie au quotidien et à renforcer les
                liens des patients avec les professionnels de santé.
                La Web-application est à destination des patients atteints de
                polyarthrite rhumatoïde.
              </p>
              <p>
                L&#39;utilisateur reconnaît être pleinement informé de ce que
                les informations contenues sur l&#39;Web- application n&#39;ont
                pas vocation, de quelle manière que ce soit à :
                <br />
                - donner un avis médical;
                <br />
                - fournir un diagnostic;
                <br />
                - remplacer la consultation, l&#39;avis ou les recommandations
                d&#39;un professionnel de santé ;
                <br />
                La Web-application n&#39;est pas un dispositif médical ou de
                télémédecine.
                <br />
                L’ANDAR ne pourra être tenue responsable à quel titre que ce
                soit en cas de dommage allégué ou avéré résultant, directement
                ou indirectement, de la consultation ou de l&#39;utilisation de
                ces informations.
              </p>
              <h3>Propriété Intellectuelle/ Licence d&#39;exploitation</h3>
              <p>
                La reproduction, la diffusion électronique ainsi que tout autre
                usage des textes, images, bases de données, codes sources,
                charte graphique, marques et logo sont formellement interdites.
                <br />
                La Web-application est fournie à l&#39;utilisateur pour ses
                besoins propres et à titre strictement privé à l&#39;exclusion
                de toute utilisation commerciale.
              </p>
              <h3>Conditions d&#39;accès</h3>
              <p>
                La Web-application est réservée :
                <br />
                (1) aux personnes majeures ou aux personnes mineures titulaires
                d&#39;une autorisation parentale leur permettant d&#39;utiliser
                la Web-application.
                <br />
                (2) aux personnes ayant souscrites à toutes les conditions
                d&#39;utilisation de leur équipement/appareil de téléphonie
                mobile, de leur opérateur de téléphonie et de
                la Web-application.
                <br />
                Il appartient à l&#39;utilisateur de conserver le mot de passe
                de la Web-application, de le renouveler et de s&#39;assurer
                que son terminal et/ou son mot de passe soi(en)t stocké(s) dans
                des conditions assurant leur sécurité. En cas de perte du mot de
                passe de la Web-application, il n&#39;existe aucune
                possibilité pour l’ANDAR de le renvoyer à l&#39;utilisateur,
                aucune donnée relative à la Web-application n&#39;étant
                stockée. L’Andar ne pourra être tenue pour responsable en cas
                d&#39;utilisation frauduleuse par un tiers des moyens
                d&#39;accès accordés à l&#39;utilisateur.
                <br />
                Toute utilisation de la Web-application en fraude des droits
                de l’ANDAR et/ou de l&#39;opérateur de téléphonie ou d&#39;un
                tiers propriétaire de l&#39;équipement/appareil de téléphonie
                mobile expose l&#39;utilisateur à des poursuites.
                <br />
                L&#39;accès à la Web-application est gratuit.
                L&#39;abonnement mobile et les frais de connexion à internet
                permettant d&#39;y accéder sont à la charge de
                l&#39;utilisateur.
              </p>
              <h3>Pharmacovigilance</h3>

              <p>
                La Web-application n&#39;a pas pour objet de recueillir des
                informations relatives à la pharmacovigilance. L&#39;utilisateur
                de la Web-application peut déclarer tout événement
                indésirable au centre régional de pharmacovigilance (CRPV) dont
                il dépend (coordonnées disponibles sur le site Internet de
                l&#39;ANSM www.ansm.sante.fr ou dans le Dictionnaire Vidal®).
              </p>
              <h3>
                Informatique et Libertés - Protection des données personnelles
              </h3>
              <p>
                L’ANDAR n&#39;est destinataire d&#39;aucune donnée personnelle
                concernant les utilisateurs de l&#39;Web- application.
              </p>
              <h3>Cookies</h3>
              <p>
                Cette Web-application utilise des cookies pour améliorer votre
                expérience lorsque vous naviguez sur la Web-application. Parmi
                ces cookies, les cookies qui sont classés comme nécessaires sont
                stockés sur votre navigateur car ils sont aussi indispensables
                au fonctionnement des fonctionnalités de base du site.
              </p>
              <h3>Responsabilité</h3>
              <p>
                L’ANDAR ne fournit aucune assistance technique pour cette
                Web-application.
                <br />
                L’ANDAR fournit la Web-application exempte de toute garantie
                de compatibilité avec le matériel mobile de l&#39;utilisateur.
                L’ANDAR ne saurait être tenue pour responsable de tout dommage,
                direct ou indirect, subi par l&#39;utilisateur qui pourrait
                résulter de l&#39;accès, de l&#39;installation, de la mise en
                œuvre ou de l&#39;utilisation de la Web-application. L’ANDAR ne
                saurait être également tenue pour responsable des
                fonctionnalités ou des informations qui y figurent ou qui sont
                transmises par tout autre moyen (en particulier SMS).
                <br />
                L’ANDAR ne garantit pas l&#39;absence d&#39;erreur ou de
                dysfonctionnement de la Web-application, quelle qu&#39;en soit
                la nature. L’ANDAR décline toute responsabilité concernant
                l&#39;opportunité des décisions prises par l&#39;utilisateur sur
                la seule foi des informations publiées sur la Web-application ou
                transmises par tout autre moyen et les modalités d&#39;exécution
                desdites décisions. L&#39;utilisation faite des informations et
                contenus demeure sous les seules responsabilités et contrôles de
                l&#39;utilisateur.
                <br />
                L’ANDAR décline toute responsabilité en cas de dommages subis
                par l&#39;utilisateur à raison notamment de la perte, de la
                détérioration ou de l&#39;altération de fichiers, de la
                transmission de virus qui pourraient infecter son équipement
                informatique ou tout autre bien à l&#39;occasion de la connexion
                et/ou de la consultation et/ou de l&#39;utilisation de la
                Web-application et de ses fonctionnalités.
                <br />
                L&#39;utilisateur est seul responsable de la souscription
                d&#39;un abonnement pour l&#39;utilisation de son
                appareil/équipement de téléphonie mobile et du maintien en
                vigueur de cet abonnement.
                <br />
                La responsabilité de L’ANDAR ne pourra être engagée en cas de
                non-respect par l&#39;utilisateur de la législation en vigueur,
                en France ou à l&#39;étranger, y compris à partir de
                l&#39;utilisation de la Web-application.
              </p>
              <h3>Garanties</h3>
              <p>
                L&#39;utilisateur garantit l’ANDAR, ainsi que toutes les
                personnes impliquées dans la création, la production et la
                distribution de la Web-application, contre toute demande,
                responsabilité, frais et dépens résultant de la violation par
                l&#39;utilisateur des présentes conditions d&#39;utilisation ou
                en relation avec l&#39;usage de la Web-application ou de
                l&#39;une de ses fonctionnalités.
              </p>
              <h3>Mise à jour</h3>
              <p>
                L’ANDAR fait ses meilleurs efforts pour proposer sur sa
                Web-application des informations à jour. Toutes les informations
                portées à la connaissance de l&#39;utilisateur de la
                Web-application ont été sélectionnées à une date déterminée.
                Elles peuvent nécessiter une mise à jour (sous réserve notamment
                de téléchargement par l&#39;utilisateur). L’ANDAR ne saurait
                être tenue responsable de toute erreur ou omission.
              </p>
              <h3>Accès à la Web-application</h3>
              <p>
                L’ANDAR se réserve le droit de résilier, suspendre, modifier et
                interrompre l&#39;accès à tout ou partie de la Web-application,
                à tout moment, et sans notification préalable, pour quelque
                motif que ce soit. L’ANDAR ne pourra être tenue pour responsable
                d&#39;une interruption ou d&#39;une suspension de la Web-
                application quelle qu&#39;en soit la cause (notamment opération
                de maintenance ou incident sur le réseau internet).
                <br />
                L’ANDAR se réserve par ailleurs le droit de résilier ou de
                restreindre l&#39;accès à la Web-application à tout internaute
                qui utiliserait la Web-application en violation des présentes
                conditions d&#39;utilisation et/ ou tenterait de se connecter de
                façon non autorisée notamment par une utilisation frauduleuse du
                système ou par une usurpation de code d&#39;accès, cette liste
                n&#39;étant pas exhaustive.
              </p>
              <h3>Convention de preuve</h3>
              <p>
                Les registres informatisés conservés dans les systèmes
                informatiques de l’ANDAR ou d&#39;une société mandatée par
                l’ANDAR dans des conditions raisonnables de sécurité, seront
                considérés comme les preuves des communications et des
                différentes transmissions des informations entre les
                utilisateurs et l’ANDAR. La conservation et l&#39;archivage des
                données sont effectués sur un support fiable et durable
                conformément à l&#39;article 1348 du Code Civil.
              </p>
              <h3>
                Modifications des mentions légales/ conditions d&#39;utilisation
              </h3>
              <p>
                L’ANDAR se réserve le droit de modifier et d&#39;actualiser les
                présentes mentions légales et conditions d&#39;utilisation à
                tout moment et sans préavis. Il est donc conseillé de se référer
                régulièrement à la dernière version de ces mentions légales et
                conditions d&#39;utilisation.
              </p>
              <h3>Loi applicable - Règlement des Litiges</h3>
              <p>
                La loi applicable à la Web-application est la loi française. Les
                contenus sont destinés à un public français et sont conformes à
                la réglementation française.
                <br />
                En cas de litige portant sur l&#39;exécution des présentes
                mentions et conditions d&#39;utilisation, les parties pourront
                recourir à une procédure de médiation ou à tout autre mode
                alternatif de règlement des différends.
              </p>
              <p class="revision">Dernière révision : Décembre 2020</p>
            </v-card-text>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
h1{
  line-height: 1.2em;
  margin: 1em auto;
}
h2{
  line-height: 1.1em;
  margin: 1em auto;
}
p{
  text-align: justify;
}
.revision{
  font-style: italic;
  text-align: right;
}
</style>