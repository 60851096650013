<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex xs-12 sm8 md8>
        <v-card class="elevation-12 main__card mx-auto">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-toolbar dark color="blue">
              <v-toolbar-title
                >Création d'un nouveau mot de passe</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text>
              <v-text-field
                v-model="password"
                name="password"
                label="Mot de passe"
                type="password"
                prepend-icon="mdi-lock"
                required
                :rules="[rules.required, rules.password]"
              ></v-text-field>
              <v-text-field
                name="password"
                label="Confirmez le mot de passe"
                type="password"
                prepend-icon="mdi-lock"
                required
                :rules="[rules.required, checkPass]"
                v-model="confirm_password"
                @keyup.enter="confirm()"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn rounded color="black" dark @click="cancel()"
                >annuler</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                rounded
                color="success"
                :disabled="!valid"
                @click="confirm()"
                :loading="loading"
              >
                valider
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "ConfirmReset",
  data: () => ({
    password: "",
    confirm_password: "",
    valid: true,
    loading: false,
    rules: {
      required: (value) => !!value || "requis",
      password: (value) => {
        const patternPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
        return (
          !!patternPass.test(value) ||
          "8 caractères minimum, dont au moins une majuscule, une minuscule et un chiffre"
        );
      },
    },
  }),
  computed: {
  },
  methods: {
    confirm() {
      this.loading = true;
      this.$store
        .dispatch("CONFIRMRESETPASS", {
          password: this.password,
          token: this.getToken(),
        })
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.success) {
            this.$store.commit("SET_NOTIFICATION", {
              display: true,
              text: response.success,
              timeout: 36000,
              alertClass: "success",
            });
          }
          if(response.error){
            this.$store.commit("SET_NOTIFICATION", {
              display: true,
              text: response.error,
              timeout: 36000,
              alertClass: "red",
            });
          }
          

          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = true;
        });
    },
    cancel() {
      // TODO: erase password reset in query
      this.$router.push({ name: "home" });
    },
    getToken(){
      let token = this.$route.params.resetPassToken;
      return token;
    },
    checkPass(value) {
      return (
        value === this.password || "Les mots de passe ne correspondent pas"
      );
    },
  },
};
</script>

<style>
</style>