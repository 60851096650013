<template>
  <v-footer dark padless>
    <v-card flat tile class="footer__content indigo white--text text-center">
      <v-card-text>
        <a href="https://polyarthrite-andar.com/" target="_blank" class="footer__logo">
          <v-avatar tile size="62" width="150" class="mx-5">
            <img :src="require('@/assets/andar.png')" alt="logo andar" />
          </v-avatar>
        </a>
        <a href="https://www.lilly.fr/fr/index.aspx" target="_blank" class="footer__logo">
          <v-avatar tile size="62" width="100" class="mx-5">
            <img :src="require('@/assets/lilly.png')" alt="logo lilly" />
          </v-avatar>
        </a>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        Version numérique développée par l’ANDAR, d’après le guide de discussion réalisé par le laboratoire Lilly en
collaboration avec des professionnels de santé et l’association ANDAR.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        <small> {{ new Date().getFullYear() }} — Kalevapuro</small>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
};
</script>

<style scoped>
v-footer {
  margin-bottom: 0;
}
.footer__content{
  width: 100%;
}
</style>