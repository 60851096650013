/* eslint-disable no-unused-vars */
<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex xs-12 sm8 md8>
        <v-form  ref="form" v-model="valid" lazy-validation>
          <v-card class="elevation-12 main__card mx-auto">
            <v-toolbar dark color="blue">
              <v-toolbar-title>Connexion</v-toolbar-title>
            </v-toolbar>
            <v-alert
              color="error"
              dark
              dismissible
              type="warning"
              :value="error"
              class="message--error"
            >
            L'email ou le mot de passe est incorrect.
            </v-alert>
            <v-card-text>
              <v-text-field 
                v-model="email" 
                name="login" 
                label="Email" 
                type="email" 
                prepend-icon="mdi-email"
                required
                :rules="[rules.required, rules.email]"
              >
              </v-text-field>
              <v-text-field 
                v-model="password" 
                name="password" 
                label="Mot de passe" 
                type="password" 
                prepend-icon="mdi-lock"
                required
                :rules="[rules.required]"
                @keyup.enter="login()"
              >
              </v-text-field>
             <router-link :to="{name: 'reset-pass'}">Mot de passe oublié ?</router-link>
            </v-card-text>
            <v-card-actions>
              <v-btn rounded dark color="primary" to="/inscription">S'inscrire</v-btn>
              <v-spacer></v-spacer>
              <v-btn 
                rounded 
                color="success" 
                @click.prevent="login()"
                :disabled="!valid"
                :loading="loading"
              >
                Se connecter
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: "Login",
  data: () => ({
    email: '',
    password: '',
    error: false,
    valid: true,
    loading: false,
    rules: {
      required: (value) => !!value || "requis",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !!pattern.test(value) || "email invalide";
      },
    }
  }),
  computed: {
  },
  methods: {
    login() {
      this.loading = true;
      this.$store.dispatch("LOGIN", {
        username: this.email,
        password: this.password
      })
      .then(success => {
        this.loading = false;
        this.$store.commit("SET_USER", {
            isLogged: true,
            email: success.user
        });
        this.$router.push({name:'dashboard'});
      })
      // eslint-disable-next-line no-unused-vars
      .catch(error => {
        this.loading = false;
        this.error = true;
      })
    },
  }
};
</script>

<style scoped>
.main__card{
  max-width: 700px;
}
.message--error{
  max-width: 95%;
  margin: 0.4em auto
}
</style>