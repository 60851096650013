<template>
  <div id="dashboard">
    <div class="wave-container d-flex flex-column">    
      <h1 class="text-center mt-3 mx-1 mt-8 mx-md-5 py-3 white--text header--title">Espace personnel</h1>
    </div>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path fill="#3f51b5" fill-opacity="1" d="M0,32L80,37.3C160,43,320,53,480,80C640,107,800,149,960,138.7C1120,128,1280,64,1360,32L1440,0L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
</svg>

<v-container>
  <v-row class="my-2">
          <v-card
            color="blue"
            dark
            class="col-11 mx-auto"
          >
            <v-card-title class="headline">Ici, vous pourrez tenir à jour vos informations concernant vos consultations </v-card-title>
            <v-card-subtitle>Pour préparer une nouvelle consultation, cliquez sur le bouton !</v-card-subtitle>
            <v-card-actions>
              <v-btn small color="primary" class="mx-auto mx-md-0" to="/nouvelle-consultation">Préparer une nouvelle consultation</v-btn>
            </v-card-actions>
          </v-card>
    </v-row>
    <v-row class="my-2">
      <Consultations />
    </v-row>
</v-container>
  </div>
</template>

<script>
import Consultations from './Consultations'

import {mapGetters} from 'vuex'

export default {
    name: 'Dashboard',
    components: {
      Consultations
    },
    computed: {
      ...mapGetters(["USER"])
    }
}
</script>


<style scoped>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
.header--title{
  font-size: 3em;
}
.wave-container {
  background-color:#3f51b5;
  position: relative;
  color: #FFF;
  text-align: center;
  overflow: hidden;
  padding: 1em;
}
</style>