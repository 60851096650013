<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex xs-12 sm8 md8>
        <v-form  ref="form" v-model="valid" lazy-validation @submit.prevent="reset()">
          <v-card class="elevation-12 main__card mx-auto">
            <v-toolbar dark color="blue">
              <v-toolbar-title>Réinitialisation du mot de passe</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field 
                v-model="email" 
                name="login" 
                label="Email" 
                type="email" 
                prepend-icon="mdi-email"
                required
                :rules="[rules.required, rules.email]"
              >
              </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn rounded dark color="primary" to="/connexion">Retour connexion</v-btn>
              <v-spacer></v-spacer>
              <v-btn 
                rounded 
                color="success" 
                @click="reset()"
                :disabled="!valid"
                :loading="loading"
              >
                Envoyer
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {

  data: () => ({
    email: '',
    error: false,
    loading:false,
    valid: true,
    rules: {
      required: (value) => !!value || "requis",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !!pattern.test(value) || "email invalide";
      },
    }
  }),
  methods: {
    reset() {
      this.loading = true;
      this.$store.dispatch("RESETPASS", {
        email: this.email,
      })
      .then((response) => {
        console.log(response)
        this.loading = false;
        if(response.success){
          this.$store.commit("SET_NOTIFICATION", {
            display: true,
            text: "Un email de réinitialisation vous a été envoyé à l'adresse " + this.email,
            timeout: 36000,
            alertClass: "success",
          });
        }
        if(response.error === "You have already requested a reset password email. Please check your email or try again soon."){
          this.$store.commit("SET_NOTIFICATION", {
            display: true,
            text: 'Vous avez déjà demandé une réinitialisation de votre mot de passe. Veuillez vérifier vos emails et retenter plus tard',
            timeout: 36000,
            alertClass: "red",
          });          
        }
        if(response.error && response.error !== "You have already requested a reset password email. Please check your email or try again soon."){
          this.$store.commit("SET_NOTIFICATION", {
            display: true,
            text: response.error,
            timeout: 36000,
            alertClass: "red",
          });
        }
        

        this.$router.push({name: 'home'});
      })
      .catch(error => {
        this.loading = false;
        console.log(error)
        this.error = true;
      })
    },
  }
}
</script>

<style>

</style>