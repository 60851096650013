<template>
  <v-container fill-height>
    <div class="col-12 col-md-6 col-xl-4 mx-auto text-center" v-for="(doctor, i) in DOCTORS" :key="i">
        <v-card class="my-3">
            <v-toolbar dark color="blue">
              <v-toolbar-title class="doctor__name">{{ doctor.title }} {{ doctor.firstname }} {{ doctor.lastname }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="blue--text">
                <h2>{{ doctor.job }}</h2>
                <div class="divider--custom"></div>
                <div class="d-flex flex-column justify-center">
                    <div class="">
                        <v-icon large color="blue">mdi-phone</v-icon>
                        <div>{{ doctor.phone }}</div>
                    </div>
                </div>
                <div class="divider--secondary"></div>
                <div class="d-flex flex-column justify-center">
                    <div class="">
                        <v-icon large color="blue">mdi-email</v-icon>
                        <div>{{ doctor.email }}</div>
                    </div>
                </div>
                <div class="divider--secondary"></div>
                <div class="d-flex flex-column justify-center">
                    <div class="">
                        <v-icon large color="blue">mdi-city</v-icon>
                        <div>{{ doctor.address }}</div>
                    </div>
                </div>
                <div class="divider--secondary"></div>
            </v-card-text>
            <v-card-actions>
                <v-btn color="red" dark small @click="remove(doctor.id)"><v-icon>mdi-trash-can</v-icon></v-btn>
                <v-spacer></v-spacer>
                <a :href="'mailto: ' + doctor.email" class="link--button"><v-btn color="success" dark><v-icon>mdi-email</v-icon></v-btn></a>
                <a :href="'tel: ' + doctor.phone" class="link--button"><v-btn color="success" dark><v-icon>mdi-phone</v-icon></v-btn></a>
            </v-card-actions>
        </v-card>
    </div>
       <v-card class="col-12 col-md-6 col-xl-4 mx-auto text-center">
            <v-toolbar dark color="blue">
              <v-toolbar-title class="doctor__name">Ajouter un professionnel de santé</v-toolbar-title>
            </v-toolbar>
            <v-card-text v-if="!doctorForm">
                <v-btn color="success" dark block @click="doctorForm=true"><v-icon>mdi-plus</v-icon></v-btn>
            </v-card-text>
            <v-form v-if="doctorForm">
                <v-card-text class="blue--text">
                    <v-select
                    v-model="title"
                    :items="titles"
                    label="Titre"
                    :rules="[rules.required]"
                    @change="$v.select.$touch()"
                    @blur="$v.select.$touch()"
                    />
                    <v-text-field
                        v-model="firstname"
                        label="Prénom"
                        :rules="[rules.required]"
                    />
                    <v-text-field
                        v-model="lastname"
                        label="Nom"
                        :rules="[rules.required]"
                    />
                    <v-select
                        v-model="job"
                        :items="jobs"
                        label="Profession"
                        :rules="[rules.required]"
                    />
                    <v-text-field
                        v-model="phone"
                        label="Numéro de téléphone"
                        :rules="[rules.required, rules.phone]"
                    />
                    <v-text-field
                        v-model="email"
                        label="Adresse e-mail"
                        :rules="[rules.required, rules.email]"
                    />
                    <v-text-field
                        v-model="address"
                        label="Adresse"
                        :rules="[rules.required]"
                    />
                    <v-text-field
                        v-model="postalCode"
                        label="Code postal"
                        :rules="[rules.required, rules.postalCode]"
                    />
                    <v-text-field
                        v-model="town"
                        label="Ville"
                        :rules="[rules.required]"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-btn color="red" dark @click="cancel()">annuler</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" dark @click="submit()" :loading="loading">valider</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
  </v-container>
</template>

<script>

import {mapGetters} from 'vuex';

export default {
    name: 'doctors',
    data: () => ({
        loading: false,
        doctorForm: false,
        title: "",
        titles: [
            "Docteur",
            "Professeur",
            "Monsieur",
            "Madame"
        ],
        firstname: "",
        lastname: "",
        job: "",
        jobs: [
            "Rhumatologue hospitalier", 
            "Rhumatologue libéral", 
            "Médecin traitant", 
            "Médecin du travail", 
            "Masseur-Kinésithérapeute", 
            "Podo-orthésiste", 
            "Infirmière d'éducation thérapeutique", 
            "Sexologue", 
            "Psychologue", 
            "Assistante Sociale", 
            "Ergothérapeute", 
            "Autre spécialité"
        ],
        email: "",
        phone: "",
        address: "",
        postalCode: "",
        town: "",
        rules: {
        required: (value) => !!value || "requis",
        email: (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !!pattern.test(value) || "email invalide";
        },
        phone: (value) => {
            // eslint-disable-next-line no-useless-escape
            let phone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
            return !!phone.test(value) || "Numéro invalide, entrez la totalité des numéros sans espaces ou caractères spéciaux";
        },
        postalCode: (value) => {
            // eslint-disable-next-line no-useless-escape
            let postalCode = /^(([1-95]{2}|2A|2B)[0-9]{3})$|^[971-974]$/ ;
            return !!postalCode.test(value) || "Code postal invalide";
        }

        
        }
    }),
    computed: {
      ...mapGetters(['DOCTORS']),
    },
    mounted () {
     this.$store.dispatch("GET_DOCTORS")
      .then(() => {
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        console.log(error)
        this.error = true;
      });
   },
    methods: {
        cancel() {
            this.doctorForm = false;
        },
        submit() {
            this.loading = true;
            this.$store.dispatch("ADD_DOCTOR", {
                title: this.title,
                firstname: this.firstname,
                lastname: this.lastname,
                job: this.job,
                email: this.email,
                phone: this.phone,
                address: this.address + ", " + this.postalCode + " " + this.town
            })
            .then(success => {
                this.loading = false;
                this.doctorForm = false;
                this.$store.commit("SET_NOTIFICATION", {
                    display: true,
                    text: success.message,
                    timeout: 8000,
                    alertClass: "success",
                });
                this.$store.dispatch("GET_DOCTORS")
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error)
                    this.error = true;
                });
            })
            .catch(error => {
                this.loading = false;
                console.log(error)
                this.error = true;
            })
        },
        remove(id){
            this.$store.dispatch("REMOVE_DOCTOR", {id: id})
            .then(success => {
                this.$store.commit("SET_NOTIFICATION", {
                    display: true,
                    text: success.message,
                    timeout: 8000,
                    alertClass: "success",
                });
                this.$store.dispatch("GET_DOCTORS")
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error)
                    this.error = true;
                });
            })
            .catch(error => {
                console.log(error);
                this.error = true;
            })
        }
    }
}
</script>

<style scoped>
.doctor__name{
    font-size:medium;
}
.divider--custom{
  height: 5px;
  background-color: #2196F3;
  width: 30%;
  margin: 2em auto;
  border-radius: 20px;
}

.divider--secondary{
  height: 3px;
  background-color: #2196F3;
  width: 20%;
  margin: 1em auto;
  border-radius: 20px;
}
.link--button{
    margin: auto 3px;
}

@media only screen and (min-width: 600px) {
.doctor__name{
    font-size:large
}
}

</style>