import axios from 'axios'


export default {
    state: {
        // STEP 1
        date: new Date().toISOString().substr(0, 10),
        globalCare: 50,
        treatment: 50,
        lifeQuality: 50,
        overallSatisfaction: 50,

        //STEP 2
        pain: {
            label: 'Douleur',
            rating: 50,
            color: '',
            comment: '',
        },
        tireness: {
            label: 'Fatigue',
            rating: 50,
            color: '',
            comment: '',
        },
        sleep: {
            label: 'Sommeil',
            rating: 50,
            color: '',
            comment: '',
        },
        social: {
            label: 'Relation Sociales',
            rating: 50,
            color: '',
            comment: '',
        },
        activity: {
            label: 'Activités ( voyages, sport)',
            rating: 50,
            color: '',
            comment: '',
        },
        work: {
            label: 'Travail',
            rating: 50,
            color: '',
            comment: '',
        },
        intimate: {
            label: 'Vie intime et sexualité',
            rating: 50,
            color: '',
            comment: '',
        },
        mood: {
            label: 'Moral',
            rating: 50,
            color: '',
            comment: '',
        },
        mobility: {
            label: 'Mobilité',
            rating: 50,
            color: '',
            comment: '',
        },

        // STEP 3
        limitedByAnswer: '',
        victoryAnswer: '',
        goals: [{
                goal: '',
                timeToDoIt: '',
            },
            {
                goal: '',
                timeToDoIt: '',
            },
            {
                goal: '',
                timeToDoIt: '',
            },
        ],

        // STEP 4
        questions: [],
    },

    getters: {
        CONSULTATION: state => {
            return state;
        },
        STEP1: state => {
            let step1Data = {
                date: state.date,
                globalCare: state.globalCare,
                treatment: state.treatment,
                lifeQuality: state.lifeQuality,
                overallSatisfaction: state.overallSatisfaction,
            };
            return step1Data;
        },
        STEP2: state => {
            let step2Data = {
                pain: state.pain,
                tireness: state.tireness,
                sleep: state.sleep,
                social: state.social,
                activity: state.activity,
                work: state.work,
                intimate: state.intimate,
                mood: state.mood,
                mobility: state.mobility,
            };
            return step2Data;
        },

        STEP3: state => {
            let step3Data = {
                limitedByAnswer: state.limitedByAnswer,
                victoryAnswer: state.victoryAnswer,
                goals: state.goals
            };
            return step3Data;
        },

        STEP4: state => {
            return state.questions;
        },
    },
    mutations: {

        SET_CONSULTATION_DATE: (state, {
            date
        }) => {
            state.date = date
        },
        SET_GLOBALCARE: (state, {
            rate
        }) => {
            state.globalCare = rate
        },
        SET_TREATMENT: (state, {
            rate
        }) => {
            state.treatment = rate
        },
        SET_LIFEQUALITY: (state, {
            rate
        }) => {
            state.lifeQuality = rate
        },
        SET_OVERALLSATISFACTION: (state, {
            rate
        }) => {
            state.overallSatisfaction = rate
        },
        SET_LIMITEDBYANSWER: (state, {
            answer
        }) => {
            state.limitedByAnswer = answer
        },
        SET_VICTORYANSWER: (state, {
            answer
        }) => {
            state.victoryAnswer = answer
        },
        SET_QUESTIONS: (state, 
            questions
        ) => {
            state.questions = questions
        },

        SET_PAIN: (state, {
            rating
        }) => {
            state.pain.rating = Number(rating);
        },
        SET_ACTIVITY: (state, {
            rating
        }) => {
            state.activity.rating = Number(rating);
        },

        UNSET_ALL: (state) => {
            state.date= new Date().toISOString().substr(0, 10);
            state.globalCare = 50;
            state.treatment= 50;
            state.lifeQuality= 50;
            state.overallSatisfaction= 50;
    
            //STEP 2
            state.pain= {
                label: 'Douleur',
                rating: 50,
                color: '',
                comment: '',
            };
            state.tireness= {
                label: 'Fatigue',
                rating: 50,
                color: '',
                comment: '',
            };
            state.sleep= {
                label: 'Sommeil',
                rating: 50,
                color: '',
                comment: ''
            };
            state.social= {
                label: 'Relation Sociales',
                rating: 50,
                color: '',
                comment: '',
            };
            state.activity= {
                label: 'Activités ( voyages, sport)',
                rating: 50,
                color: '',
                comment: '',
            };
            state.work= {
                label: 'Travail',
                rating: 50,
                color: '',
                comment: '',
            };
            state.intimate= {
                label: 'Vie intime et sexualité',
                rating: 50,
                color: '',
                comment: '',
            };
            state.mood= {
                label: 'Moral',
                rating: 50,
                color: '',
                comment: '',
            };
            state.mobility= {
                label: 'Mobilité',
                rating: 50,
                color: '',
                comment: '',
            };
        }
    },

    actions: {
        ADD_CONSULTATION: ({
            commit
        }, {
            consultationData
        }) => {
            console.log(commit)
            console.log(consultationData)
            return new Promise((resolve, reject) => {
                axios
                    .post(`consultations/create`, {
                        date: consultationData.date,
                        globalCare: consultationData.globalCare,
                        treatment: consultationData.treatment,
                        lifeQuality: consultationData.lifeQuality,
                        overallSatisfaction: consultationData.overallSatisfaction,

                        limitedByAnswer: consultationData.limitedByAnswer,
                        victoryAnswer: consultationData.victoryAnswer,
                        questions: consultationData.questions,

                        pain: consultationData.pain,
                        tireness: consultationData.tireness,
                        sleep: consultationData.sleep,
                        social: consultationData.social,
                        activity: consultationData.activity,
                        work: consultationData.work,
                        intimate: consultationData.intimate,
                        mood: consultationData.mood,
                        mobility: consultationData.mobility,
                        goals: consultationData.goals,
                    })
                    .then(({
                        data,
                        status
                    }) => {
                        console.log(data)
                        if (status === 201) {
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
    }
}