<script src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit" async defer></script>
<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex xs-12 sm8 md8>
        <v-card class="elevation-12 main__card mx-auto">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-toolbar dark color="blue">
              <v-toolbar-title>Inscription</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-alert
                dark
                :value="userExists"
                color="error"
                icon="mdi-alert-circle"
              >
                Cette adresse email existe déjà
              </v-alert>
              <v-text-field
                v-model="email"
                name="email"
                label="Email"
                type="email"
                prepend-icon="mdi-email"
                required
                :rules="[rules.required, rules.email]"
                @click="userExists = false"
              ></v-text-field>
              <v-text-field
                v-model="password"
                name="password"
                label="Mot de passe"
                type="password"
                prepend-icon="mdi-lock"
                required
                :rules="[rules.required, rules.password]"
              ></v-text-field>
              <v-text-field
                name="password"
                label="Confirmez le mot de passe"
                type="password"
                prepend-icon="mdi-lock"
                required
                :rules="[rules.required, checkPass]"
                v-model="confirm_password"
                @keyup.enter="register()"
              ></v-text-field>
              <div>
                <vue-recaptcha
                  :sitekey="this.sitekey" 
                  :loadRecaptchaScript="true"
                  @verify="onVerify"
                  @expired="onExpired"
                />
                <small class="error__captcha">{{ errorCaptcha }}</small>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn rounded color="black" dark to="/connexion"
                >se connecter</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                rounded
                color="success"
                :disabled="!valid"
                @click="validate()"
                :loading="loading"
              >
                inscription
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha';
export default {
  name: "Signup",
  data: () => ({
    recaptcha: null,
    userExists: false,
    valid: true,
    sitekey: "6LeyFt4ZAAAAAKZKlRJG4V9wAUR0WNQqQbreEMuh",
    email: "",
    password: "",
    confirm_password: "",
    loading: false,
    rules: {
      required: (value) => !!value || "requis",
      email: (value) => {
        const patternEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !!patternEmail.test(value) || "email invalide";
      },
      password: (value) => {
        const patternPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
        return !!patternPass.test(value) || "8 caractères minimum, dont au moins une majuscule, une minuscule et un chiffre";
      },
    },
    errorCaptcha: null,
  }),
  components: {
    'vue-recaptcha':VueRecaptcha,
  },
  computed: {},
  methods: {
    onVerify(response) {
      this.errorCaptcha = null;
      this.recaptcha = response;
    },
    onExpired() {
      console.log('Expired')
    },
    register() {
      this.loading = true;
      this.$store
        .dispatch("REGISTER", {
          email: this.email,
          password: this.password,
        })
        .then(({ status }) => {
          this.loading = false;
          this.$store.commit("SET_NOTIFICATION", {
            display: true,
            text:
              "Votre compte a été créé avec succès. Vous pouvez maintenant vous connecter.",
            timeout: 8000,
            alertClass: "success",
          });
          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.userExists = true;
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        if(this.recaptcha){
          this.register();
        }else{
          this.errorCaptcha = "Veuillez cocher la case";
        }
      }
    },
    checkPass(value) {
      return (
        value === this.password || "Les mots de passe ne correspondent pas"
      );
    },
  },
};
</script>

<style scoped>
.error__captcha{
  font-style: italic;
  color: red;
}
.main__card{
  max-width: 700px;
}
</style>