<script>
import { Radar } from "vue-chartjs";

export default {
  name: "DataGraph",
  extends: Radar,

  mounted() {
    this.chartdata.datasets[0].data.push(this.metrics.activity.rating);
    this.chartdata.labels.push(this.metrics.activity.label);

    this.chartdata.datasets[0].data.push(this.metrics.social.rating);
    this.chartdata.labels.push(this.metrics.social.label);

    this.chartdata.datasets[0].data.push(this.metrics.tireness.rating);
    this.chartdata.labels.push(this.metrics.tireness.label);

    this.chartdata.datasets[0].data.push(this.metrics.sleep.rating);
    this.chartdata.labels.push(this.metrics.sleep.label);

    this.chartdata.datasets[0].data.push(this.metrics.work.rating);
    this.chartdata.labels.push(this.metrics.work.label);

    this.chartdata.datasets[0].data.push(this.metrics.intimate.rating);
    this.chartdata.labels.push(this.metrics.intimate.label);

    this.chartdata.datasets[0].data.push(this.metrics.pain.rating);
    this.chartdata.labels.push(this.metrics.pain.label);

    this.chartdata.datasets[0].data.push(this.metrics.mood.rating);
    this.chartdata.labels.push(this.metrics.mood.label);

    this.chartdata.datasets[0].data.push(this.metrics.mobility.rating);
    this.chartdata.labels.push(this.metrics.mobility.label);

    this.renderChart(this.chartdata, this.options);
  },

  computed: {},

  methods: {
    average() {
      return (
        (this.metrics.pain.rating +
          this.metrics.tireness.rating +
          this.metrics.sleep.rating +
          this.metrics.social.rating +
          this.metrics.activity.rating +
          this.metrics.work.rating +
          this.metrics.intimate.rating +
          this.metrics.mood.rating +
          this.metrics.mobility.rating) /
        8
      );
    },
  },

  props: {
    metrics: Object,
  },

  data: () => ({
    chartdata: {
      labels: [],
      datasets: [
        {
          backgroundColor: 'rgba(33, 150, 243, 0.5)',
          data: [],
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: true,
      pointLabels: {
        display: false,
      },
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 20,
          bottom: 20,
        },
      },
      legend: {
        display: false,
      },
      scale: {
        ticks: {
          display: true,
          fontStyle: 'bold',
          min: 0,
          max: 100,
          stepSize: 20,
          callback: function (values, index) {
            values = [null, 'mal', 'moyen', 'bien', 'très bien', '']
            return values[index]
          }
        },
      },
      tooltips: {
        enabled: false,
      }
    },
  }),
};
</script>

<style>
</style>