import Vue from 'vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'

import axios from 'axios'

import store from './stores/store'
import routes from './routes'

import App from './App.vue'

Vue.config.productionTip = false;


Vue.use(VueRouter);

axios.defaults.baseURL = 'https://ma-consult-pr.fr/api/v1';
// axios.defaults.baseURL = 'https://ma-consult-pr.fr/api/v1'; // PROD
// axios.defaults.baseURL = 'https://ma-consult-pr.fr/api/v1'; // DEV
axios.defaults.withCredentials = true;

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: routes,
  base: '/'
})


// Interceptors to refresh token

let isRefreshing = false;
let subscribers = [];

axios.interceptors.response.use(response => {
  return response;
}, error => {
  const {
    config,
    response: {
      status,
      data
    }
  } = error;

  const originalRequest = config;
   if (data.message === "JWT Token not found") {
     localStorage.clear();
     store.commit("SET_USER", {
        isLogged: false,
      });
    router.push({
      name: "login"
    });
    store.commit("SET_NOTIFICATION", {
      display: true,
      text:
        "Session expirée, veuillez vous reconnecter",
      timeout: 8000,
      alertClass: "red",
    });
    return Promise.reject(false);
  }

  if (originalRequest.url.includes("login_check")) {
    return Promise.reject(error);
  }

  if (status === 401 && data.message === "Expired token") {
    if (!isRefreshing) {
      isRefreshing = true;
      store.dispatch("REFRESH_TOKEN")
        .then(({
          status
        }) => {
          if (status === 200 || status === 204) {
            isRefreshing = false;
          }
          subscribers = [];
        })
        .catch(error => {
          console.log(error);
        });
    }

    const requestSubscribers = new Promise(resolve => {
      subscribeTokenRefresh(() => {
        resolve(axios(originalRequest));
      });
    });

    onRefreshed();
    return requestSubscribers;
  }
});

function subscribeTokenRefresh(callback) {
  subscribers.push(callback);
}

function onRefreshed() {
  subscribers.map(callback => callback())
}

subscribers = [];
// /Interceptors to refresh token

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')