<template>
  <v-list dense shaped dark class="d-flex flex-column justify-content-between">
    <v-container>

    <v-list-item link to="/">
      <v-list-item-action>
        <v-icon>mdi-home</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Accueil</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    
    <v-list-item-group v-if="USER.isLogged">

      <v-list-item link to="/espace-perso">
        <v-list-item-action>
          <v-icon>mdi-monitor-dashboard</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Tableau de bord</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/docteurs">
        <v-list-item-action>
          <v-icon>mdi-doctor</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Carnet d'adresses</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list-item-group>

    <v-list-item-group v-if="!USER.isLogged">

      <v-list-item link to="/inscription">
        <v-list-item-action>
          <v-icon>mdi-account-plus</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>S'inscrire</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/connexion">
        <v-list-item-action>
          <v-icon>mdi-login</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Se connecter</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list-item-group>

    <v-divider class="mx-12"></v-divider>

    <v-list-item link to="/mentions-legales">
      <v-list-item-action>
        <v-icon>mdi-bank</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>Mentions légales</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link to="/a-propos" class="mb-0">
      <v-list-item-action>
        <v-icon>mdi-card-account-details</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>À propos</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    </v-container>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Menu",
  data: () => ({}),
  methods: {

  },
  computed: {
    ...mapGetters(["USER"]),

  },
};
</script>

<style>
</style>