<template>
<v-container fill-height>
    

  <div class="NotFound mx-auto">
      <v-layout row wrap>
        <v-flex xs12>
          <v-card height="400" color="transparent" flat>
            <h1 class="foorOfoor">404</h1>
            <div class="display-3 mt-5">Page introuvable</div>
            <div class="grey--text lighten-5">
                La page que vous essayez d'atteindre n'existe pas.
            </div>
            <div class="paragraph-text mt-2">
              <br />
              <br />
              <v-btn
              dark
              to="/"
              color="indigo"
              >Retourner à l'accueil</v-btn>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
  </div>
</v-container>
</template>

<script>

export default {
    name: 'FourOfour',

}
</script>
<style scoped>
.foorOfoor {
    font-size: 5em;
    color: #3F51B5;

}
</style>