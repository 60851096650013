<template>
  <div>
    <v-toolbar dark color="blue">
      <v-toolbar-title>1. Informations générales</v-toolbar-title>
    </v-toolbar>
    <v-alert v-if="error" color="error" dark icon="mdi-close" class="col-8 mx-auto my-1"></v-alert>
    <v-card-text class="px-0 px-md-10 indigo--text">
      <v-col cols="10" sm="8" md="6" class="mx-auto">
        <h2 class="my-2">Quand est ma prochaine consultation?</h2>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Date de votre consultation"
              v-model="computedDateFormatted"
              prepend-icon="mdi-calendar"
              readonly
              hint="format jour/mois/année"
              persistent-hint
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="consultationData.date"
            locale="fr"
            @input="menu = false; setStateDate(consultationData.date)"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" class="mx-auto mt-3">
        <h2 class="my-2">Quel est mon degré de satisfation actuel?</h2>
        <p>
          <strong>La polyarthrite rhumatoïde peut avoir un impact sur l'ensemble des activités de la vie quotidienne.</strong> Pour que votre médecin puisse se rendre compte avec précision des conséquences de votre maladie, nous vous invitons à remplir ce formulaire.
        </p>

        <SmileyForm
          :withTitle="true"
          color="orange"
          title="Prise en charge globale"
          :inversedColor="false"
          @update:rating="setGlobalCare($event)"
          :rating="computedGlobalCare"
        />
        <SmileyForm
          :withTitle="true"
          color="red"
          title="Traitement médicamenteux"
          :inversedColor="false"
          @update:rating="setTreatment($event)"
          :rating="computedTreatment"
        />
        <SmileyForm
          :withTitle="true"
          color="green darken-3"
          title="Qualité de vie"
          :inversedColor="false"
          @update:rating="setLifeQuality($event)"
          :rating="computedLifeQuality"
        />
      </v-col>
      <v-col cols="12" class="mx-auto mt-3">
        <h2 class="my-2">Quel est mon état général ces dernières semaines?</h2>
        <p>
          <strong>Prenez en compte votre humeur, vos préoccupations, votre santé globale.</strong>
        </p>
        <SmileyForm
          :withTitle="false"
          :inversedColor="true"
          @update:rating="setOverallSatisfaction($event)"
          :rating="computedOverallSatisfaction"
        />
      </v-col>
    </v-card-text>
  </div>
</template>

<script>
import SmileyForm from "./SmileyForm";
import { mapGetters } from "vuex";

export default {
  name: "Step1",
  data: () => ({
    consultationData: {
      date: new Date().toISOString().substr(0, 10),
    },
    menu: false,
    modal: false,
    on: null,
    attrs: null,
    error: null,
  }),

  computed: {
    ...mapGetters(["STEP1"]),
    computedDateFormatted() {
      if(localStorage.consultationDate){
        this.setStateDate(localStorage.consultationDate)
        return this.formatDate(this.STEP1.date);
      }else{
        return this.formatDate(this.STEP1.date);
      }
    },
    computedGlobalCare(){
      if(localStorage.globalCare !== 50){
        this.setGlobalCare(Number(localStorage.globalCare))
        return this.STEP1.globalCare;
      }else{
        return this.STEP1.globalCare;
      }
    },
    computedTreatment(){
      if(localStorage.treatment !== 50){
        this.setTreatment(Number(localStorage.treatment))
        return this.STEP1.treatment;
      }else{
        return this.STEP1.treatment;
      }
    },
    computedLifeQuality(){
      if(localStorage.lifeQuality !== 50){
        this.setLifeQuality(Number(localStorage.lifeQuality))
        return this.STEP1.lifeQuality;
      }else{
        return this.STEP1.lifeQuality;
      }
    },
    computedOverallSatisfaction(){
      if(localStorage.overallSatisfaction !== 50){
        this.setOverallSatisfaction(Number(localStorage.overallSatisfaction))
        return this.STEP1.overallSatisfaction;
      }else{
        return this.STEP1.overallSatisfaction;
      }
    },
  },
  props: {
    registerData: Function,
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.consultationData.date);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    setStateDate(date) {
      this.$store.commit("SET_CONSULTATION_DATE", { date: date });
      localStorage.setItem('consultationDate', date);
    },
    setGlobalCare(rate) {
      this.$store.commit("SET_GLOBALCARE", {rate});
      localStorage.setItem('globalCare', rate);
    },
    setTreatment(rate) {
      this.$store.commit("SET_TREATMENT", {rate});
      localStorage.setItem('treatment', rate);
    },
    setLifeQuality(rate) {
      this.$store.commit("SET_LIFEQUALITY", {rate});
      localStorage.setItem('lifeQuality', rate);
    },
    setOverallSatisfaction(rate) {
      this.$store.commit("SET_OVERALLSATISFACTION", {rate});
      localStorage.setItem('overallSatisfaction', rate);
    },
  },
  components: {
    SmileyForm,
  },
};
</script>

<style>
.responsive__title {
  font-size: 1.1em;
}
</style>