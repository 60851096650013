/* eslint-disable no-unused-vars */
import axios from 'axios'


export default {
    state: {
        user: {
            isLogged: false,
            email:'',
            token: '',
        }
    },
    getters: {
        USER: state => {
            return state.user;
        }
    },
    mutations: {
        SET_USER: (state, {isLogged, token, email}) => {
            state.user.isLogged = isLogged;
            state.user.token = token;
            state.user.email = email;
            localStorage.setItem('isLogged', isLogged);
        },
        
    },
    actions: {
        LOGIN: ({commit}, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`login_check`, payload)
                    .then(({data, status}) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        REGISTER: ({commit}, {email, password}) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`register`, {
                        email,
                        password
                    })
                    .then(({data, status}) => {
                        if (status === 201) {
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        RESETPASS: ({commit}, payload) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`reset-password`, payload)
                    .then(({data, status}) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        CONFIRMRESETPASS: ({commit}, {token, password}) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`reset-password/reset/${token}`, {password})
                    .then(({data, status}) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        REFRESH_TOKEN: () => {
            return new Promise((resolve, reject) => {
                axios.post(`token/refresh`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },

        LOGOUT: () => {
            return new Promise((resolve, reject) => {
                axios.delete(`logout`)
                    .then(({data, status}) => {
                        if (status === 200) {
                            resolve(true);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })/* eslint-disable no-unused-vars */
                    /* eslint-disable no-unused-vars */
            })
        }
    }
}