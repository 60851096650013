<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs-12>
        <v-stepper v-model="step" class="my-3">
          <v-stepper-header>
            <v-stepper-step
              step="1"
              :complete="isCompleted(1)"
              value="1"
              @click="step = 1"
              :color="stepColor(1)"
            >
              Informations générales
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              step="2"
              :complete="isCompleted(2)"
              value="2"
              @click="step = 2"
              :color="stepColor(2)"
            >
              Évaluation
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              step="3"
              :complete="isCompleted(3)"
              value="3"
              @click="step = 3"
              :color="stepColor(3)"
            >
              Bilan
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              step="4"
              :complete="isCompleted(4)"
              value="4"
              @click="step = 4"
              :color="stepColor(4)"
            >
              Questions
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
        <v-form>
          <v-card class="elevation-12">
            <Step1 v-if="step == 1" />
            <Step2 v-if="step == 2" />
            <Step3 v-if="step == 3" />
            <Step4 v-if="step == 4" />
            <v-card-actions>
              <v-btn
                rounded
                dark
                small
                color="primary"
                @click.prevent="checkAlert()"
                >annuler</v-btn
              >
              <v-btn
                v-if="step > 1"
                rounded
                dark
                small
                color="secondary"
                @click.prevent="previousStep()"
              >
                <v-icon>mdi-arrow-left</v-icon>
                précédent
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                v-if="step < totalSteps"
                rounded
                dark
                small
                color="success"
                @click.prevent="nextStep()"
              >
                {{ formNext }}
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
              <v-btn
                v-if="step == totalSteps"
                rounded
                dark
                small
                color="success"
                @click.prevent="submitConsultation(CONSULTATION)"
                :loading="loading"
              >
                Enregistrer
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Step1 from "./consultationForm/Step1";
import Step2 from "./consultationForm/Step2";
import Step3 from "./consultationForm/Step3";
import Step4 from "./consultationForm/Step4";

import { mapGetters } from "vuex";

export default {
  name: "ConsultationForm",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
  },
  data: () => ({
    step: 1,
    totalSteps: 4,
    error: null,
    formNext: "suivant",
    step1Data: null,
    loading: false,
  }),
  computed: {
    ...mapGetters(["CONSULTATION"]),

    // check the step's current status to redirect to the last uncompleted step
    currentStep() {
      if (this.isCompleted(1) && this.isCompleted(2) && this.isCompleted(3)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return (this.step = 4);
      }
      if (this.isCompleted(1) && this.isCompleted(2)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return (this.step = 3);
      }
      if (this.isCompleted(1)) {
        console.log("coucou")
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return (this.step = 2);
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return (this.step = 1);
    },
  },

  methods: {
    // Submit the whole form
    submitConsultation(consultationData) {
      this.loading = true;
      this.$store
        .dispatch("ADD_CONSULTATION", {
          consultationData,
        })
        .then(() => {
          this.$store.commit("SET_NOTIFICATION", {
            display: true,
            text: "Votre consultation a bien été enregistrée.",
            timeout: 8000,
            alertClass: "success",
          });
          this.loading = false;
          localStorage.clear();
          localStorage.setItem("isLogged", true);
          this.$store.commit("UNSET_ALL");
          this.$router.push({name:"dashboard"});
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    previousStep() {
      if (this.step > 1) {
        window.scrollTo(0, 0);
        this.step--;
      }
    },
    nextStep() {
      window.scrollTo(0, 0);
      this.step++;
    },
    // check for form cancellation
    checkAlert() {
      let response = window.confirm(
        "Les données saisies seront perdues si vous continuez"
      );
      if (response == true) {
        localStorage.clear();
        this.$store.commit("UNSET_ALL");
        localStorage.setItem("isLogged", true);
        return this.$router.push({ name: "dashboard" });
      } else {
        return null;
      }
    },
    // check if a step have already been filled to display status on stepper
    isCompleted(step) {
      if (step === 1) {
        if (
          !isNaN(this.CONSULTATION.globalCare) &&
          !isNaN(this.CONSULTATION.treatment) &&
          !isNaN(this.CONSULTATION.lifeQuality) &&
          !isNaN(this.CONSULTATION.overallSatisfaction)
        ) {
          return true;
        } else {
          return false;
        }
      }
      if (step === 2) {
        if (
          localStorage.pain ||
          localStorage.tireness ||
          localStorage.sleep ||
          localStorage.social ||
          localStorage.activity ||
          localStorage.work ||
          localStorage.intimate ||
          localStorage.mood ||
          localStorage.mobility
        ) {
          return true;
        } else {
          return false;
        }
      }
      if (step === 3) {
        if (this.CONSULTATION.limitedByAnswer !== "" || localStorage.limitedByAnswer || (localStorage.goal0 && localStorage.limitedByAnswer0)) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    // change step color according to its status
    stepColor(step) {
      if (step === 1) {
        if (this.isCompleted(1)) {
          return "success";
        }
      }
      if (step === 2) {
        if (this.isCompleted(2)) {
          return "success";
        }
      }
      if (step === 3) {
        if (this.isCompleted(3)) {
          return "success";
        }
      }

      return "primary";
    },
  },
};
</script>

<style scoped>
.v-stepper__step {
  cursor: pointer;
}
</style>