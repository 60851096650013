<template>
    <v-skeleton-loader
        v-bind="attrs"
        type="list-item-avatar"
        transition="wave"
    />
</template>

<script>
export default {
    data: () => ({
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    })
}
</script>

<style>

</style>