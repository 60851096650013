<template>
  <div id="home">
    <div class="wave-container d-flex flex-column">
      <h1
        class="text-center mt-3 mx-1 mt-8 mx-md-5 py-3 white--text header--title"
      >
        Ma Polyarthrite Rhumatoïde, j'en parle
      </h1>
      <p class="text-center white--text my-5">
        Guide de discussion sous forme d'application web
      </p>
      <v-btn
        v-if="!USER.isLogged"
        color="white"
        class="indigo--text mx-auto"
        link
        to="/inscription"
        width="150px"
        >S'enregistrer
      </v-btn>
      <v-icon color="white" class="arrow my-8">mdi-arrow-down-bold</v-icon>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" class="wave">
      <path
        fill="#3f51b5"
        fill-opacity="1"
        d="M0,32L80,37.3C160,43,320,53,480,80C640,107,800,149,960,138.7C1120,128,1280,64,1360,32L1440,0L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
      ></path>
    </svg>
    <div class="content d-flex">
      <v-col cols="12">
        <v-row>
          <v-col class="col-12 col-md-8 mx-auto">
            <div class="content__title">
              La polyarthrite rhumatoïde est une maladie qui peut passer
              inaperçue pour les personnes qui vous entourent. La douleur, les
              raideurs, la gêne : vous seul les ressentez…
            </div>
          </v-col>
        </v-row>
              <v-img
              src="@/assets/logo-llp.png"
              max-width="200px"
              class="mx-auto illustration"
              >
              </v-img>
        <v-row class="mb-10">
          <v-col class="col-12 col-md-5 mx-auto content__text">
            C’est pourquoi il est important d’en parler autour de vous. Avec vos
            proches tout d’abord, pour leur expliquer les mouvements ou les
            activités qui sont devenus incon- fortables ou pour leur indiquer
            les moments où vous vous sentez fatigué(e). Avec votre médecin
            également : plus vous lui décrirez avec précision ce que vous
            ressentez, plus il sera à même de vous aider de la façon la plus
            adaptée possible.
          </v-col>
          <v-col class="col-12 col-md-5 mx-auto content__text">
            <h3>La communication est essentielle !</h3>

            Ce guide pourra servir de base pour dialoguer avec votre médecin aﬁn
            d’optimiser votre prise en charge. Ainsi au ﬁl des pages vous
            pourrez, décrire vos symptômes, évaluer l’impact de la maladie sur
            votre quotidien, noter vos objectifs ainsi que les questions que
            vous vous posez. Cet outil pourra servir de suivi.
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "Home",
  computed: {
    ...mapGetters(["USER"]),

  },
};
</script>

<style scoped>
.arrow {
  display: none;
}
.header--title {
  font-size: 3em;
}
.wave-container {
  background-color: #3f51b5;
  position: relative;
  color: #fff;
  text-align: center;
  overflow: hidden;
  padding: 1em;
}
.content {
  max-width: 1700px;
  margin: -10em auto auto auto;
}

.content__title {
  background-color: #3f51b5;
  color: white;
  padding: 1em 2em;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
  border-radius: 20px;
}
.content__text {
  background-color: #3f51b5;
  color: white;
  padding: 2em 3em;
  font-size: 1em;
  text-align: justify;
  border-radius: 20px;
}

@media only screen and (max-width: 600px) {
  .wave-container {
    height: 100vh;
  }
  .arrow {
    display: inline;
  }
  .content {
    margin-top: -3em;
    width: 100%;
  }
  .content__title {
    padding: 0.5em;
    font-size: 1.5em;
    margin: 0;
  }
  .content__text {
    padding: 1em 1.5em;
    font-size: 1em;
    border-radius: 0;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1100px) {
  .content {
    width: 90%;
    margin: -5em auto auto auto;
  }
  .content__text {
    padding: 1em 1.5em;
    font-size: 1em;
  }
}
</style>