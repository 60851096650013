<template>
  <v-container fill-height>
    <v-card class="mx-auto main__card" min-width="80%">
      <v-toolbar dark color="blue">
        <v-toolbar-title
          >Consultation du
          {{ parseDate(CONSULTATION_DB.date) }}</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text
        class="d-flex flex-column flex-lg-row justify-lg-center align-center"
      >
        <div class="left px-lg-10">
          <DataGraph
            v-if="CONSULTATION_DB"
            :metrics="CONSULTATION_DB"
            :key="CONSULTATION_DB.id"
          />
          <div v-if="CONSULTATION_DB">
            <h3 class="card__title">Commentaires</h3>
            <div v-if="CONSULTATION_DB.activity.comment" class="comment">
              <span class="comment__title">{{ CONSULTATION_DB.activity.label }}:</span> {{ CONSULTATION_DB.activity.comment }}
            </div>
            <div v-if="CONSULTATION_DB.social.comment" class="comment">
              <span class="comment__title">{{ CONSULTATION_DB.social.label }}: </span> {{ CONSULTATION_DB.social.comment }}
            </div>
            <div v-if="CONSULTATION_DB.tireness.comment" class="comment">
              <span class="comment__title">{{ CONSULTATION_DB.tireness.label }}: </span> {{ CONSULTATION_DB.tireness.comment }}
            </div>
            <div v-if="CONSULTATION_DB.sleep.comment" class="comment">
              <span class="comment__title">{{ CONSULTATION_DB.sleep.label }}: </span> {{ CONSULTATION_DB.sleep.comment }}
            </div>
            <div v-if="CONSULTATION_DB.work.comment" class="comment">
              <span class="comment__title">{{ CONSULTATION_DB.work.label }}: </span> {{ CONSULTATION_DB.work.comment }}
            </div>
            <div v-if="CONSULTATION_DB.intimate.comment" class="comment">
              <span class="comment__title">{{ CONSULTATION_DB.intimate.label }}: </span> {{ CONSULTATION_DB.intimate.comment }}
            </div>
            <div v-if="CONSULTATION_DB.pain.comment" class="comment">
              <span class="comment__title">{{ CONSULTATION_DB.pain.label }}: </span> {{ CONSULTATION_DB.pain.comment }}
            </div>
            <div v-if="CONSULTATION_DB.mood.comment" class="comment">
              <span class="comment__title">{{ CONSULTATION_DB.mood.label }}: </span> {{ CONSULTATION_DB.mood.comment }}
            </div>
            <div v-if="CONSULTATION_DB.mobility.comment" class="comment">
              <span class="comment__title">{{ CONSULTATION_DB.mobility.label }}: </span> {{ CONSULTATION_DB.mobility.comment }}
            </div>
          </div>
        </div>
        <div class="right px-lg-10">
          <v-row class="my-2 metric">
            Prise en charge globale
            <v-progress-linear
              v-model="CONSULTATION_DB.globalCare"
              :color="pickColor(CONSULTATION_DB.globalCare)"
              height="25"
              width="90%"
              rounded
              dark
            >
              <v-icon>{{ pickSmiley(CONSULTATION_DB.globalCare) }}</v-icon>
            </v-progress-linear>
          </v-row>
          <v-row class="my-2 metric">
            Traitement
            <v-progress-linear
              v-model="CONSULTATION_DB.treatment"
              :color="pickColor(CONSULTATION_DB.treatment)"
              height="25"
              width="90%"
              rounded
              dark
            >
              <v-icon>{{ pickSmiley(CONSULTATION_DB.treatment) }}</v-icon>
            </v-progress-linear>
          </v-row>
          <v-row class="my-2 metric">
            Qualité de vie
            <v-progress-linear
              v-model="CONSULTATION_DB.lifeQuality"
              :color="pickColor(CONSULTATION_DB.lifeQuality)"
              height="25"
              width="90%"
              rounded
              dark
            >
              <v-icon>{{ pickSmiley(CONSULTATION_DB.lifeQuality) }}</v-icon>
            </v-progress-linear>
          </v-row>
          <v-row class="my-2 metric metric--last">
            Satisfaction globale
            <v-progress-linear
              v-model="CONSULTATION_DB.overallSatisfaction"
              :color="pickColor(CONSULTATION_DB.overallSatisfaction)"
              height="25"
              width="90%"
              rounded
              dark
            >
              <v-icon>{{
                pickSmiley(CONSULTATION_DB.overallSatisfaction)
              }}</v-icon>
            </v-progress-linear>
          </v-row>
          <div class="my-5 px-lg-3">
            <h3 class="card__title">Aspects limitants de ma PR sur mon quotidien</h3>
            <div v-if="CONSULTATION_DB.limitedByAnswer !== ''" class="my-3 px-lg-10">
              <v-icon class="mx-2">mdi-cancel</v-icon
              >{{ CONSULTATION_DB.limitedByAnswer }}
            </div>
            <div v-if="CONSULTATION_DB.limitedByAnswer === ''" class="my-3 px-lg-10">
              Vous n'avez pas signalé de limitations liées à votre PR.
            </div>
          </div>
          <div class="my-5  px-lg-3">
            <h3 class="card__title">
              La plus grande réussite/victoire depuis ma précédente consultation
            </h3>
            <div v-if="CONSULTATION_DB.victoryAnswer !== ''" class="my-3 px-lg-10">
              <v-icon class="mx-2">mdi-arm-flex-outline</v-icon
              >{{ CONSULTATION_DB.victoryAnswer }}
            </div>
            <div v-if="CONSULTATION_DB.victoryAnswer === ''" class="my-3 px-lg-10">
              Vous n'avez pas signalé réussite particulière.
            </div>
          </div>
          <div class="my-5  px-lg-3">
            <h3 class="card__title">Les objectifs que je me suis fixés pour cette consultation</h3>
            <div
              v-if="
                CONSULTATION_DB.goals[0] ||
                CONSULTATION_DB.goals[1] ||
                CONSULTATION_DB.goals[2]
              "
              class="px-lg-10"
            >
              <div v-for="(goal, index) in CONSULTATION_DB.goals" :key="index">
                <div class="my-3 mx-auto">
                  <v-icon class="mx-2">mdi-flag-checkered</v-icon>
                  {{ goal.goal }}
                </div>
              </div>
            </div>
            <div v-else>Vous n'avez pas défini d'objectifs.</div>
          </div>
          <div class="my-5  px-lg-3">
            <h3 class="card__title">Les questions à poser lors de ma consultation</h3>
            <div v-if="CONSULTATION_DB.questions[0]">
              <div
                v-for="(question, index) in CONSULTATION_DB.questions"
                :key="index"
                class="px-lg-10"
              >
                <div class="my-3 mx-auto">
                  <v-icon class="mx-2">mdi-comment-question-outline</v-icon>
                  {{ question.question }}
                </div>
              </div>
            </div>
            <div v-else>Vous n'avez pas renseigné de questions</div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="my-3">
          <v-btn dark rounded color="black" to="/espace-perso" class="mx-auto">
            <v-icon left> mdi-arrow-left </v-icon>
            Retour sur le tableau de bord
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DataGraph from "./DataGraph";

export default {
  name: "consultation_detail",
  data: () => ({
    showGraph: false,
    globalCare: 0,
  }),
  methods: {
    loadGraph() {
      this.showGraph = true;
    },
    pickColor(rating) {
      if (rating >= 80) {
        return "success";
      }
      if (rating <= 60 && rating > 40) {
        return "green darken-3";
      }
      if (rating <= 40 && rating > 20) {
        return "orange darken-3";
      }
      if (rating <= 20) {
        return "red";
      }
    },
    pickSmiley(rating) {
      if (rating >= 80) {
        return "mdi-emoticon";
      }
      if (rating <= 60 && rating > 40) {
        return "mdi-emoticon-happy";
      }
      if (rating <= 40 && rating > 20) {
        return "mdi-emoticon-neutral";
      }
      if (rating <= 20) {
        return "mdi-emoticon-sad";
      }
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  props: {
    consultationId: {
      required: true,
    },
  },
  components: {
    DataGraph,
  },
  beforeMount() {
    this.$store.dispatch("GET_CONSULTATION_DB", {
      id: this.$route.params.consultationId,
    });
  },
  computed: {
    ...mapGetters(["CONSULTATION_DB"]),
  },
};
</script>

<style scoped>
.left{
  max-width: 100%;
}
.comment{
  margin: 0.5em 0;
}
.comment__title{
  padding-right: 10px;
  font-weight: bold;
  font-style: italic;
}
.card__title{
  background-color: #2196F3;
  color: white;
  text-align: center;
  border-radius: 20px;
  padding: 2px;
}
@media only screen and (min-width: 1264px) {
  .main__card {
    max-width: 1200px;
  }
  .left {
    max-width: 40%;
  }
  .metric{
    max-width: 500px;
    margin: 0 auto;
  }
}
</style>