<template>
  <v-card outlined class="mx-auto my-3">
    <v-toolbar v-if="withTitle" dense dark :color="color">
      <v-toolbar-title class="responsive__title">{{ title }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row class="d-flex justify-space-between justify-md-center">
        <v-btn 
          :color="button1.color" 
          large 
          fab 
          @click="selected(20)"
          class="mx-md-3"
        >
          <v-icon color="white">mdi-emoticon-sad{{ button1.smiley }}</v-icon>
        </v-btn>

        <v-btn
          :color="button2.color" 
          large
          fab
          @click="selected(40)"
          class="mx-md-3"
        >
          <v-icon color="white"
            >mdi-emoticon-neutral{{ button2.smiley }}</v-icon
          >
        </v-btn>

        <v-btn
          :color="button3.color" 
          large
          fab
          @click="selected(60)"
          class="mx-md-3"
        >
          <v-icon color="white">mdi-emoticon-happy{{ button3.smiley }}</v-icon>
        </v-btn>

        <v-btn 
          :color="button4.color"  
          large 
          fab 
          @click="selected(80)"
          class="mx-md-3"
        >
          <v-icon color="white">mdi-emoticon{{ button4.smiley }}</v-icon>
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SmileyForm",
  data: () => ({
    button1: {
      pressed: false,
      color: "red",
      smiley: "-outline",
    },
    button2: {
      pressed: false,
      color: "orange darken-3",
      smiley: "-outline",
    },
    button3: {
      pressed: false,
      color: "green darken-3",
      smiley: "-outline",
    },
    button4: {
      pressed: false,
      color: "success",
      smiley: "-outline",
    },
  }),
  beforeMount(){
    this.selected(this.rating);
  },
  computed: {
    ratingInput: {
      get() {
        return this.rating;
      },
      set(newRating) {
        this.$emit("update:rating", newRating);
      },
    },
  },
  props: {
    withTitle: Boolean,
    color: String,
    title: String,
    rating: Number,
  },
  methods: {
    selected(value) {
      this.ratingInput = value;

      this.button1.pressed = false;
      this.button1.smiley = "-outline";
      this.button1.color = "red";

      this.button2.pressed = false;
      this.button2.smiley = "-outline";
      this.button2.color = "orange darken-3";

      this.button3.pressed = false;
      this.button3.smiley = "-outline";
      this.button3.color = "green darken-3";

      this.button4.pressed = false;
      this.button4.smiley = "-outline";
      this.button4.color = "success";

      if (value === 20) {

        this.button1.pressed = true;
        this.button1.smiley = "";
        this.button2.color = "grey";
        this.button3.color = "grey";
        this.button4.color = "grey";

      } else if (value === 40) {

        this.button2.pressed = true;
        this.button2.smiley = "";
        this.button1.color = "grey";
        this.button3.color = "grey";
        this.button4.color = "grey";

      } else if (value === 60) {

        this.button3.pressed = true;
        this.button3.smiley = "";
        this.button1.color = "grey";
        this.button2.color = "grey";
        this.button4.color = "grey";

      } else if (value === 80) {

        this.button4.pressed = true;
        this.button4.smiley = "";
        this.button1.color = "grey";
        this.button2.color = "grey";
        this.button3.color = "grey";

      }
    },
  },
};
</script>

<style>
</style>