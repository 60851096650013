/* eslint-disable no-unused-vars */
import axios from 'axios'


export default {
    state: {
        doctor: {
            firstname: '',
            lastname: '',
            title: '',
            job: '',
            phone: '',
            email:'',
            address: '',
        },
        doctors: [],
    },
    getters: {
        DOCTOR: state => {
            return state.doctor;
        },
        DOCTORS: state => {
            return state.doctors
        },
    },
    mutations: {
        SET_DOCTOR: (state, payload) => {
            state.doctor.firstname = payload.firstname;
            state.doctor.lastname = payload.lastname;
            state.doctor.title = payload.title;
            state.doctor.job = payload.job;
            state.doctor.phone = payload.phone;
            state.doctor.email = payload.email;
            state.doctor.address = payload.address;
        },
        SET_DOCTORS: (state, payload) => {
            state.doctors = payload;
        },
        
    },
    actions: {
        GET_DOCTORS: async ({commit}) => {
            let {data} = await axios.get(`doctors`)
            commit("SET_DOCTORS", data)
         },
        ADD_DOCTOR: ({commit}, {title, firstname, lastname, job, phone, email, address}) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`doctors/create`, {
                        title,
                        firstname,
                        lastname,
                        job,
                        phone,
                        email,
                        address
                    })
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 201) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
        REMOVE_DOCTOR: ({commit}, {id}) => {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`doctors/delete/${id}`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },
    }
}