<template>
  <v-container fill-height id="about">
    <v-layout align-center justify-center>
      <v-flex xs-12 sm8 md8>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card class="elevation-12 main__card mx-auto">
            <v-toolbar dark color="blue">
              <v-toolbar-title>&#192; propos</v-toolbar-title>
            </v-toolbar>
              <v-img
              src="@/assets/apropos.jpg"
              max-width="100%"
              class="mx-auto illustration"
              >
              </v-img>
            <v-card-text class="px-md-5 px-lg-10 py-10 my-2">

                <p class="text__title">
                  Version numérique développée par l’ANDAR, d’après le guide de discussion réalisé par le laboratoire Lilly en
collaboration avec l’association Andar et des professionnels de santé :
                </p>
                <div class="divider--main"></div>
                <div class="text__author">
                <div>Mme Daphné <span class="text--bold">CASTELAIN-DEVILLERS</span></div>
                <div>Psychologue, Lille</div>
                </div>
                <v-divider class="divider--second"></v-divider>
                <div class="text__author">
                <div>Mme Christelle <span class="text--bold">corne</span></div>
                <div>Patiente, ANDAR</div>
                </div>
                <v-divider class="divider--second"></v-divider>
                <div class="text__author">
                <div>Dr Sabrina <span class="text--bold">dadoun</span></div>
                <div>Rhumatologue à la clinique Geoﬀroy Saint-Hilaire, Paris</div>
                </div>
                <v-divider class="divider--second"></v-divider>
                <div class="text__author">
                <div>Pr Philippe <span class="text--bold">gaudin</span></div>
                <div>Rhumatologue, CHU de Grenoble</div>
                </div>
                <v-divider class="divider--second"></v-divider>
                <div class="text__author">
                <div>Mme Françoise <span class="text--bold">fayet</span></div>
                <div>Inﬁrmière d’éducation thérapeutique, CHU de Clermont Ferrand</div>
                </div>
                <v-divider class="divider--second"></v-divider>
                <div class="text__author">
                <div>Mme Stéphanie <span class="text--bold">paulus</span></div>
                <div>Inﬁrmière d’éducation thérapeutique, CH de Dreux</div>
                </div>
                <v-divider class="divider--second"></v-divider>
                <div class="text__author">
                <div>Mme Sonia <span class="text--bold">tropé</span></div>
                <div>Directrice, ANDAR</div>
                </div>
                <v-divider class="divider--second"></v-divider>
                <div class="text__author">
                <div>Mr Olivier <span class="text--bold">deparis</span></div>
                <div>Conjoint de patient, Paris</div>
                </div>
            </v-card-text>
          </v-card>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.illustration{
  border-bottom: 3px solid tomato;
}
.text__title{
  font-size: 1.5em;
}

.divider--main{
  height: 5px;
  background-color: tomato;
  width: 10%;
  margin: 2em auto;
  border-radius: 20px;
}

.divider--second{
  height: 5px;
  background-color: tomato;
  width: 5%;
  margin: 1em auto;
  border-radius: 20px;
}

.text--bold{
  font-weight: bold;
  text-transform: uppercase;
}
.text__author{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2em;
}
</style>