<template>
  <div id="app">
    <Layout />
  </div>
</template>

<script>

import Layout from './components/layout/Layout'

export default {

  name: 'App',

  components: {
    Layout
  },

  props: {
  },

  data: () => ({
  }),
};
</script>
