/* eslint-disable no-unused-vars */
import axios from "axios";

export default {
    state: {
        consultations: [],
        consultationDB: null,
    },
    getters: {
        CONSULTATIONS: state => {
            return state.consultations
        },
        CONSULTATION_DB: state => {
            return state.consultationDB
        },
    },
    mutations: {
        SET_CONSULTATIONS: (state, payload) => {
            state.consultations = payload;
        },
        SET_CONSULTATION_DB: (state, payload) => {
            state.consultationDB = payload;
        }
    },
    actions: {
        GET_CONSULTATIONS: async ({commit}) => {
           let {data} = await axios.get(`consultations`)
           commit("SET_CONSULTATIONS", data)
        },
        GET_CONSULTATION_DB: async (state, payload) => {
            let {data} = await axios.get(`consultations/${payload.id}`)
            state.commit("SET_CONSULTATION_DB", data)
         },
         REMOVE_CONSULTATION: ({commit}, {id}) => {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`consultations/delete/${id}`)
                    .then(({
                        data,
                        status
                    }) => {
                        if (status === 200) {
                            resolve(data);
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },

    }
}