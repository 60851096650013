import Vue from 'vue'
import Vuex from 'vuex'
import UIModule from './modules/ui'
import User from './modules/user'
import Consultation from './modules/consultation'
import Data from './modules/data'
import Doctor from './modules/doctor'
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        ui: UIModule,
        user: User,
        consultation: Consultation,
        data: Data,
        doctor: Doctor,
    }
});