<template>
  <div>
    <v-toolbar dark color="blue">
      <v-toolbar-title>2. Évaluation</v-toolbar-title>
    </v-toolbar>
    <v-alert v-if="error" color="error" dark icon="mdi-close" class="col-8 mx-auto my-1"></v-alert>
    <v-card-text class="indigo--text">
      <v-col cols="12">
        <h1 class="my-2">Comment est-ce que je me sens avec ma PR?</h1>
        <h3>Déplacez le curseur selon votre ressenti actuel (vers la droite: positif, vers la gauche: négatif)</h3>
        <v-row>
          
          <v-col cols="12" class="mt-2" v-for="(data, index) in STEP2" :key="index">
            <v-divider></v-divider>
            <h2 class="my-2" >{{ data.label }}</h2>
            <v-slider
              class="col-12 col-md-6 lg-col-4 mx-auto mt-7 slider__slider"
              v-model="data.rating"
              :thumb-size="35"
              thumb-label="always"
              :thumb-color="thumbColor(data)"
              :color="data.color"
              :track-color="data.color"
              append-icon="mdi-emoticon"
              prepend-icon="mdi-emoticon-sad"
              @end="registerLocalRating(data.label, data.rating)"
            >
              <template v-slot:thumb-label="{ value }">
                <v-icon dark >{{ thumbEmoji(value)}}</v-icon>
              </template>
            </v-slider>
            <v-text-field
              
              v-model="data.comment"
              :color="data.color"
              flat
              outlined label="Commentaires" 
              class="mt-0 pt-0"
              @keyup="registerLocalComment(data.label, data.comment)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="d-flex">
        </v-row>
      </v-col>
    </v-card-text>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "Step2",
  data: () => ({
    error: null,
    satisfactionEmojis: ["🙁", "😐", "🙂", "😊"],
    thumbColors: ["red", "orange", "yellow darken-2", "success"],
    thumbEmojis: [
      "mdi-emoticon-sad",
      "mdi-emoticon-neutral",
      "mdi-emoticon-happy",
      "mdi-emoticon",
    ],
  }),
  beforeMount(){
    if(localStorage.pain){
      this.STEP2.pain.rating = localStorage.pain
    }
    if(localStorage.painComment){
      this.STEP2.pain.comment = localStorage.painComment
    }

    if(localStorage.tireness){
      this.STEP2.tireness.rating = localStorage.tireness
    }
    if(localStorage.tirenessComment){
      this.STEP2.tireness.comment = localStorage.tirenessComment
    }

    if(localStorage.sleep){
      this.STEP2.sleep.rating = localStorage.sleep
    }
    if(localStorage.sleepComment){
      this.STEP2.sleep.comment = localStorage.sleepComment
    }

    if(localStorage.social){
      this.STEP2.social.rating = localStorage.social
    }
    if(localStorage.socialComment){
      this.STEP2.social.comment = localStorage.socialComment
    }

    if(localStorage.activity){
      this.STEP2.activity.rating = localStorage.activity
    }
    if(localStorage.activityComment){
      this.STEP2.activity.comment = localStorage.activityComment
    }

    if(localStorage.work){
      this.STEP2.work.rating = localStorage.work
    }
    if(localStorage.workComment){
      this.STEP2.work.comment = localStorage.workComment
    }

    if(localStorage.intimate){
      this.STEP2.intimate.rating = localStorage.intimate
    }
    if(localStorage.mood){
      this.STEP2.mood.rating = localStorage.mood
    }
    if(localStorage.moodComment){
      this.STEP2.mood.comment = localStorage.moodComment
    }

    if(localStorage.mobility){
      this.STEP2.mobility.rating = localStorage.mobility
    }
    if(localStorage.mobilityComment){
      this.STEP2.mobility.comment = localStorage.mobilityComment
    }
  },
  computed: {
    ...mapGetters(['STEP2']),
  },
  components: {
  },
  watch: {},
  methods: {
    registerLocalRating(label, value){

      if (label === this.STEP2.pain.label){
        localStorage.setItem('pain', Number(value));
      }
      if (label === this.STEP2.tireness.label){
        localStorage.setItem('tireness', Number(value));
      }
      if (label === this.STEP2.sleep.label){
        localStorage.setItem('sleep', Number(value));
      }
      if (label === this.STEP2.social.label){
        localStorage.setItem('social', Number(value));
      }
      if (label === this.STEP2.activity.label){
        localStorage.setItem('activity', Number(value));
      }
      if (label === this.STEP2.work.label){
        localStorage.setItem('work', Number(value));
      }
      if (label === this.STEP2.intimate.label){
        localStorage.setItem('intimate', Number(value));
      }
      if (label === this.STEP2.mood.label){
        localStorage.setItem('mood', Number(value));
      }
      if (label === this.STEP2.mobility.label){
        localStorage.setItem('mobility', Number(value));
      }
      return value;
    },

    registerLocalComment(label, value){

      if (label === this.STEP2.pain.label){
        localStorage.setItem('painComment', value);
      }
      if (label === this.STEP2.tireness.label){
        localStorage.setItem('tirenessComment', value);
      }
      if (label === this.STEP2.sleep.label){
        localStorage.setItem('sleepComment', value);
      }
      if (label === this.STEP2.social.label){
        localStorage.setItem('socialComment', value);
      }
      if (label === this.STEP2.activity.label){
        localStorage.setItem('activityComment', value);
      }
      if (label === this.STEP2.work.label){
        localStorage.setItem('workComment', value);
      }
      if (label === this.STEP2.intimate.label){
        localStorage.setItem('intimateComment', value);
      }
      if (label === this.STEP2.mood.label){
        localStorage.setItem('moodComment', value);
      }
      if (label === this.STEP2.mobility.label){
        localStorage.setItem('mobilityComment', value);
      }
      return value;
    },
    thumbColor(data) {
      if (data.rating >= 95) {
        return "success";
      }
      data.color = this.thumbColors[Math.min(Math.floor(data.rating / 25), 4)]
      return data.color;
    },

    thumbEmoji(value) {
      if (value >= 95) {
        return "mdi-emoticon";
      }
      return this.thumbEmojis[Math.min(Math.floor(value / 25), 4)];
    },
  },
};
</script>

<style scoped>
</style>