<template>
    <v-snackbar
    :color="NOTIFICATION.class"
    v-model="snackbar"
    top
    :timeout="NOTIFICATION.timeout"
    >
        {{ NOTIFICATION.text }}
        <v-btn 
            dark
            text
            @click="snackbar = false"
        >
            Fermer
        </v-btn>
    </v-snackbar>
    
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'notification',
    computed: {
        ...mapGetters(['NOTIFICATION']),
        snackbar: {
            get () {
                return this.$store.getters.NOTIFICATION.display;
            },
            set (value) {
                this.$store.commit("SET_NOTIFICATION", {
                    display: value
                })
            }
        }
    }
}
</script>