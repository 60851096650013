
<template>
  <div>
    <v-toolbar dark color="blue">
      <v-toolbar-title>3. Bilan</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="indigo--text px-0 px-md-3">
      <v-col cols="12">
        <div>
          <h2 class="my-2">
            Afin que votre rhumatologue puisse comprendre vos entraves et vos
            objectifs au quotidien, veuillez compléter les informations si
            dessous
          </h2>
          <v-divider class="mt-5"></v-divider>
          <h3 class="my-4">
            Sur quels aspects de mon quotidien ma PR me limite-t-elle ?
          </h3>
          <p>
            La maladie vous a t-elle limité(e) dans l'accomplissement de
            tâches/activités depuis la dernière consultation?
          </p>
          <v-textarea
            v-model="STEP3.limitedByAnswer"
            @keyup="setLimitedByAnswer(STEP3.limitedByAnswer)"
            outlined
            rows="3"
            type="text"
            label="commentaire"
            placeholder="exemple: cuisiner, boutonner ma chemise, faire une activité sportive, ma toilette, mon enfant, mes courses"
          ></v-textarea>
        </div>
        <div>
          <h3 class="my-4">
            Les 3 objectifs prioritaires que j'aimerais atteindre
          </h3>

          <div class="d-flex justify-space-around">
                <v-expansion-panels hover popout focusable dark>
                  <v-expansion-panel
                    v-for="(goal, index) in STEP3.goals"
                    :key="index"
                    :class="filledColor(index)"
                    class="my-1"
                  >
                    <v-expansion-panel-header>
                      <template v-slot:default="{ open }">
                        <v-row no-gutters>
                          <v-col cols="4">Objectif {{ index + 1 }}</v-col>
                          <v-col cols="8" class="">
                            <v-fade-transition leave-absolute>
                              <span v-if="open" key="0">{{
                                filledColor(index) == 'primary'
                                  ? "Remplissez votre objectif"
                                  : "objectif OK"
                              }}</span>
                              <span v-else key="1">{{
                                filledColor(index) == 'primary'
                                  ? "Saisissez un objectif"
                                  : "Cet objectif est enregistré"
                              }}</span>
                            </v-fade-transition>
                          </v-col>
                        </v-row>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-text-field
                        placeholder="Saisissez votre objectif"
                        v-model="STEP3.goals[index].goal"
                        @keyup="
                          localSave('goal', index, STEP3.goals[index].goal)
                        "
                      ></v-text-field>
                      <v-select
                        :items="timeForGoal"
                        label="Temps pour atteindre l'objectif"
                        v-model="STEP3.goals[index].timeToDoIt"
                        @change="
                          localSave(
                            'timeToDoIt',
                            index,
                            STEP3.goals[index].timeToDoIt
                          )
                        "
                      ></v-select>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
          </div>
          <div>
            <h3 class="my-4">
              Quel est votre plus grande réussite/ victoire depuis votre
              dernière consultation ?
            </h3>
            <v-textarea
              v-model="STEP3.victoryAnswer"
              @keyup="setVictoryAnswer(STEP3.victoryAnswer)"
              outlined
              rows="3"
              type="text"
              placeholder=""
            ></v-textarea>
          </div>
        </div>
      </v-col>
    </v-card-text>
  </div>
</template>
 
<script>
import { mapGetters } from "vuex";

export default {
  name: "Step3",
  data: () => ({
    timeForGoal: ["Court", "Moyen", "Long"],
  }),
  beforeMount() {
    if (localStorage.goal0 && localStorage.timeToDoIt0) {
      this.STEP3.goals[0].goal = localStorage.goal0;
      this.STEP3.goals[0].timeToDoIt = localStorage.timeToDoIt0;

      if (localStorage.goal1 && localStorage.timeToDoIt1) {
        this.STEP3.goals[1].goal = localStorage.goal1;
        this.STEP3.goals[1].timeToDoIt = localStorage.timeToDoIt1;

        if (localStorage.goal2 && localStorage.timeToDoIt2) {
          this.STEP3.goals[2].goal = localStorage.goal2;
          this.STEP3.goals[2].timeToDoIt = localStorage.timeToDoIt2;
        }
      }
    }

    if (localStorage.limitedByAnswer) {
      this.STEP3.limitedByAnswer = localStorage.limitedByAnswer
    }

    if (localStorage.victoryAnswer) {
      this.STEP3.victoryAnswer = localStorage.victoryAnswer
    }
  },
  computed: {
    ...mapGetters(["STEP3"]),
    // Get localStorage data and inject it if any
  },

  methods: {
    filledColor(index) {
      if (
        this.STEP3.goals[index].goal != "" &&
        this.STEP3.goals[index].timeToDoIt != ""
      ) {
        return "success";
      } else {
        return "primary";
      }
    },
    setVictoryAnswer(answer) {
      this.$store.commit("SET_VICTORYANSWER", { answer });
      localStorage.setItem("victoryAnswer", answer);
    },
    setLimitedByAnswer(answer) {
      this.$store.commit("SET_LIMITEDBYANSWER", { answer });
      localStorage.setItem("limitedByAnswer", answer);
    },
    localSave(type, index, data) {
      if (type === "goal") {
        localStorage.setItem("goal" + index, data);
      } else if (type === "timeToDoIt") {
        localStorage.setItem("timeToDoIt" + index, data);
      }
    },
  },
  components: {},
};
</script>
 
<style>
.w-50 {
  width: 45%;
}

.table-title {
  background-color: #ee9508;
  padding: 0.3em;
}

.no__wrap {
  white-space: normal !important;
}
</style>