<template>
  <v-expansion-panel
    :class="pickColor(average)"
    class="my-2 mx-auto main__div"
    @click="loadGraph()"
  >
    <v-expansion-panel-header>
      <v-icon class="ml-0 mr-1"> {{ pickSmiley(average) }} </v-icon>

      Consultation du {{ parseDate(consultation.date) }}
    </v-expansion-panel-header>
    <v-expansion-panel-content class="">
      <h3>Mes objectifs:</h3>
      <p v-if="!consultation.goals[0]">
        Aucun objectif n'a été fixé pour cette consultation.
      </p>
      <div v-else-if="consultation.goals[0]">
        <v-list 
          v-for="item in consultation.goals" 
          :key="item.goal" 
            class="my-3"
            dense
            rounded
            width="100%"
            color="white"
            light
        >

          <v-chip 
            label
            :color="goalColor(item.timeToDoIt)"
            dark
          >
            {{ item.timeToDoIt ? item.timeToDoIt + ' terme' : 'Terme indéfini'}}
          </v-chip>
          {{ item.goal }}
        </v-list>
      </div>
      <h3>Mes questions:</h3>
      <p v-if="!consultation.questions[0]">
        Aucune question n'a été définie pour cette consultation.
      </p>
      <div v-else-if="consultation.questions[0]">
          <v-list
            v-for="item in consultation.questions" :key="item.question"
            class="my-3"
            dense
            rounded
            width="100%"
            color="white"
            light
          >
            <v-list-item 
              v-if="item.question !== ''"
              class="my-2"
              height="20px"
            >
              <v-icon color="blue">mdi-comment-question</v-icon>
              {{ item.question }}
            </v-list-item>
          </v-list>
      </div>
      <div class="d-flex justify-space-between">
        <v-btn small rounded  dark color="red" @click="remove(consultation.id)">
          <v-icon>mdi-trash-can</v-icon>
          Supprimer
        </v-btn>
        <router-link :to="{
            name: 'consultationDetail',
            params: {
              consultationId: consultation.id
            },
        }">
        <v-btn small rounded  dark color="primary">

          Détails
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        </router-link>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "consultation",
  components: {},
  methods: {
    pickColor(rating) {
      if (rating > 60) {
        return "success";
      }
      if (40 < rating <= 60) {
        return "orange darken-4";
      }
      if (rating <= 40) {
        return "red";
      }
    },
    pickSmiley(rating) {
      if (rating > 60) {
        return "mdi-emoticon";
      }
      if (40 < rating <= 60) {
        return "mdi-emoticon-neutral";
      }
      if (rating <= 40) {
        return "mdi-emoticon-sad";
      }
    },
    goalColor(timeToDoGoal){
      if (timeToDoGoal === "Court") {
        return "red";
      }
      if (timeToDoGoal === "Moyen") {
        return "orange";
      }
      if (timeToDoGoal === "Long") {
        return "red";
      }
    },
    loadGraph() {
      this.showGraph = true;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
        remove(id){
            this.$store.dispatch("REMOVE_CONSULTATION", {id: id})
            .then(success => {
                this.$store.commit("SET_NOTIFICATION", {
                    display: true,
                    text: success.message,
                    timeout: 8000,
                    alertClass: "success",
                });
                this.$store.dispatch("GET_CONSULTATIONS")
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error)
                    this.error = true;
                });
            })
            .catch(error => {
                console.log(error);
                this.error = true;
            })
        }
  },
  props: {
    consultation: Object,
  },
  data: () => ({
    metrics: [],
    score: 0,
    average: 0,
    showGraph: false,
  }),
  mounted() {
    let metrics = {
      pain: this.consultation.pain,
      tireness: this.consultation.tireness,
      sleep: this.consultation.sleep,
      social: this.consultation.social,
      activity: this.consultation.activity,
      work: this.consultation.work,
      intimate: this.consultation.intimate,
      mood: this.consultation.mood,
      mobility: this.consultation.mobility,
    };
    this.average =
      (metrics.pain.rating +
        metrics.tireness.rating +
        metrics.sleep.rating +
        metrics.social.rating +
        metrics.activity.rating +
        metrics.work.rating +
        metrics.intimate.rating +
        metrics.mood.rating +
        metrics.mobility.rating) /
      8;
    return (this.metrics = metrics);
  },
};
</script>

<style scoped>
.main__div{
  min-width: 70%;
}
</style>