<template>
    <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex xs-12>
          <v-card class="elevation-12 mx-md-15">
            <v-toolbar dark color="indigo">
              <v-toolbar-title>Historique de vos consultations</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <p class="indigo--text">Retrouvez tous les renseignements de vos dernières consultations.</p>
              <v-expansion-panels dark class="flex-column justify-center">
                <SkeletonList v-show="loading" />
                <Consultation
                  :consultation="consultation"
                  v-for="(consultation) in CONSULTATIONS" 
                  :key="consultation.id"
                />
              </v-expansion-panels> 
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
          </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex';

import Consultation from './Consultation';
import SkeletonList from '../UI/SkeletonList';

export default {
    name: 'consultations',
    data: () => ({
      loading: false,
    }),
    components: {
        Consultation,
        SkeletonList,
    },
    computed: {
      ...mapGetters(['CONSULTATIONS']),
    },
    beforeMount(){
      this.loading = true;
    },
    mounted () {
     this.$store.dispatch("GET_CONSULTATIONS")
      .then(() => {
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        console.log(error)
        this.error = true;
      });
   }

}
</script>

<style>

</style>