export default {
    state: {
        notification: {
            display: false,
            text: "",
            timeout: 5000,
            class: 'success'
        }
    },
    getters: {
        NOTIFICATION: state => {
            return state.notification;
        }
    },
    mutations: {
        SET_NOTIFICATION: (state, {display, text, alertClass}) => {
            state.notification.display = display;
            state.notification.text = text;
            state.notification.class = alertClass;
        }
    },
    actions: {}
}