<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
      color="blue"
    >
    <Menu />
    </v-navigation-drawer>

    <v-app-bar
      app
      color="indigo"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="!drawer">Menu</v-toolbar-title>
      <v-spacer></v-spacer>

      <div v-if="USER.isLogged === true" class="d-flex">
        <div v-if="!isMobile()">
        <v-avatar color="indigo">
          <v-icon dark>
            mdi-account-circle
          </v-icon>
        </v-avatar>
        Connecté
        </div>
        <v-btn
          v-if="USER.isLogged"
          color="red"
          @click="logout()"
          dark
          small
          class="ml-10 my-auto"
        >
        <v-icon>mdi-logout</v-icon>
        Déconnexion
      </v-btn> 
      </div>
      <div v-if="USER.isLogged === false">
        <v-btn
          v-if="!USER.isLogged"
          color="green"
          link
          to="/connexion"
          dark
          small
        >
        <v-icon>mdi-login</v-icon>
        Se connecter
        </v-btn>
      </div>

    </v-app-bar>

    <v-main>
    <transition name="fade">
      <router-view></router-view>
    </transition>
    <Notification />
    <Footer />
    </v-main>
  </v-app>
</template>

<script>

import Menu from '../menu/Menu'
import Footer from './Footer'
import Notification from '../UI/Notification'
import {mapGetters} from 'vuex'

  export default {
    props: {
      source: String,
    },
    data: () => ({
      drawer: false,
    }),
    components: {
        Menu,
        Notification,
        Footer
    },
    computed: {
      ...mapGetters(["USER"])
    },
    methods: {
          logout() {
      this.$store.dispatch("LOGOUT")      
      // eslint-disable-next-line no-unused-vars
      .then(success => {
        this.$store.commit("SET_USER", {
            isLogged: false,
          });
        this.$router.replace({name: 'home'});
      })
      // eslint-disable-next-line no-unused-vars
      .catch(error => {
        this.$store.commit("SET_USER", {
            isLogged: false,
          });
        this.error = true;
        this.$router.replace({name: 'home'});
      });
      },
            isMobile() {
                if( screen.width <= 760 ) {
                    return true;
                }
                else {
                    return false;
                }
            }
    },
    mounted(){
      if (localStorage.isLogged == 'true'){
        return this.$store.commit("SET_USER", {
            isLogged: true,
          });
      } else {
        return null;
      }
    }
  }
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>