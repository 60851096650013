<template>
  <div>
    <v-toolbar dark color="blue">
      <v-toolbar-title>4. Questions</v-toolbar-title>
    </v-toolbar>
    <v-card-text class="blue--text">
      <v-col cols="12">
        <div>
          <p>Il n'y a pas de mauvaises questions, il n'y a que des bonnes réponses. Parler, dialoguer librement avec son rhumatologue permet une meilleure compréhension des symptômes, de trouver des solutions ou pistes d'amélioration pour le quotidien. N'hésitez pas à lui poser toutes vos questions</p>

          <div
            v-for="(question, index) in questions"
            :key="index"
          >
            <v-text-field
              v-model="question.question"
              outlined
              :label="'Question ' + (index + 1)"
              @keyup="setQuestions(index)"
            >
            <v-btn
              small
              slot="append"
              dark
              color="red"
              @click="removeQuestion()"
              v-show="index || ( !index && questions.length > 1)"
            >
              <v-icon
              >mdi-delete-circle</v-icon>
            </v-btn>
            </v-text-field>

          </div>
          <v-btn 
            color="primary"
            block
            @click="addQuestion()"
          >
            <v-icon>mdi-comment-plus</v-icon>
            <div> Ajouter une question </div>
          </v-btn>
        </div>
      </v-col>
    </v-card-text>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {

  data: () => ({
    questions: [
      {
        question: ''
      }
    ],
  }),
  computed: {
    ...mapGetters(['STEP4'])
  },

  methods: { 
    setQuestions(){
      this.$store.commit("SET_QUESTIONS", this.questions);
    },
    addQuestion(){
      this.questions.push({question: ''});
    },
    removeQuestion(index){
      this.questions.splice(index, 1);
    }
  }
};
</script>

<style scoped>
</style>